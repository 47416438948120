import React, { useEffect, useState } from 'react'
import { Button, Divider, Grid, Header, Label, Icon } from 'semantic-ui-react'
import { Link, useHistory } from "react-router-dom";
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'

import styles from '../styles'

import * as api from '../api'

import {
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { minutesToHMString } from '../utils';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    }
})


const DesktopContainer = (props) => {
    const [worker, setWorker] = useState({
        managerStatus: 'Worker',
        remainingHolidays: 0
    });
    const normalColor = 'yellow'
    // const activeColor = 'blue'

    const history = useHistory()

    const handleLogout = () => {
        localStorage.removeItem('token')
        history.push('/login')
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        api.checkLoggedIn(token, (res, err) => {
            if (!res)
                history.push('/login')
        });
        (async function asyncFuncation() {
            const token = localStorage.getItem('token');
            await api.getWorker(token, (response) => {
                if (response.status === 200)
                    setWorker(response.data)
            })
        })()

    }, [])

    const remainingHolidaysPercents = worker.remainingHolidays * 100 / 30

    return (
        <Media greaterThan='mobile' >
            <Grid id='grid-no-margin' style={styles.fullscreen_grid} columns={3}>
                <Grid.Row >
                    <Grid.Column>
                        <Grid stackable container columns={2} textAlign='center'>

                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column>
                                    <Header color='yellow' >Welcome, {worker.firstName}</Header>
                                    {worker.saldo
                                        ?
                                        <Label size='large' color='yellow'>
                                            Saldo
                                            <Label.Detail>{minutesToHMString(worker.saldo)}</Label.Detail>
                                        </Label>
                                        : <div />}

                                </Grid.Column>

                                <Divider vertical />

                                <Grid.Column>
                                    <div style={{ width: '20vh', height: 120 }}>
                                        <CircularProgressbarWithChildren
                                            value={remainingHolidaysPercents}
                                            strokeWidth={6}
                                            styles={buildStyles({
                                                pathColor: "orange",
                                            })}>
                                            <div style={{ fontSize: 14, marginTop: -5, textAlign: 'center' }}>
                                                <p>
                                                    Remaining holiday:
                                                </p>
                                                <p><strong>{worker.remainingHolidays}</strong></p>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Grid.Column>
                    <Grid.Column verticalAlign='top' floated='right' width={6} textAlign='right'>
                        <Link to="/new-request">
                            <Button
                                circular
                                size='huge'
                                color={normalColor}
                                content='Make a request'
                            />
                        </Link>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                    <Grid.Column textAlign='center' width={8}>
                        <Header color={normalColor} >
                            To track your work time, please use a desktop app
                        </Header>
                        <Button
                            basic
                            size='normal'
                            icon='download'
                            content='Download'
                            color='yellow'
                            onClick={() => api.downloadInstaller()}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row verticalAlign='bottom' columns={2}>
                    <Grid.Column textAlign='left'>
                        <Link to="/">
                            <Button onClick={handleLogout} >
                                <Icon name='sign-out' />
                                Logout
                            </Button>
                        </Link>
                    </Grid.Column>
                    {worker.managerStatus !== 'Worker'
                        ? <Grid.Column verticalAlign='bottom'>
                            <Link to="/requests">
                                <Button
                                    floated='right'
                                    size='big'
                                    color={normalColor}
                                    content='Requests'
                                    icon='file alternate'
                                />
                            </Link>
                            <Link to="/workers">
                                <Button
                                    floated='right'
                                    size='big'
                                    color={normalColor}
                                    content='Workers'
                                    icon='address book'
                                />
                            </Link>
                        </Grid.Column>
                        : null
                    }
                </Grid.Row>
            </Grid>
        </Media>
    )
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
}

const MobileContainer = (props) => {
    const [worker, setWorker] = useState({
        managerStatus: 'Worker',
        remainingHolidays: 0
    });

    const normalColor = 'yellow'
    // const activeColor = 'blue'

    const history = useHistory()

    const handleLogout = () => {
        localStorage.removeItem('token')
        history.push('/login')
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        api.getWorker(token, (response) => {
            if (response.status === 200)
                setWorker(response.data)
        })
    }, [])

    return (
        <Media at='mobile'>
            <Grid id='grid-no-margin' style={styles.fullscreen_grid} columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Header color={normalColor} size='medium'>
                            Welcome, {worker.firstName}
                        </Header>
                        <Header size='small'>
                            Remaining holiday: {worker.remainingHolidays} days
                        </Header>
                        {worker.saldo
                            ?
                            <Label size='large' color='yellow'>
                                Saldo
                                <Label.Detail>{minutesToHMString(worker.saldo)}</Label.Detail>
                            </Label>
                            : <div />}
                    </Grid.Column>
                    <Grid.Column>
                        <Link to="/new-request">
                            <Button
                                floated='right'
                                color={normalColor}
                                content='Make a request'
                            />
                        </Link>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                        <Header content='To track your work time, please use a desktop app' color={normalColor} />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column verticalAlign='bottom'>
                        <Button color='grey' content='Logout' onClick={handleLogout} />
                    </Grid.Column>
                    {worker.managerStatus !== 'Worker'
                        ? <Grid.Column verticalAlign='bottom'>
                            <Link to="/requests">
                                <Button
                                    floated='right'
                                    color={normalColor}
                                    content='Requests'
                                    icon='file alternate'
                                />
                            </Link>
                        </Grid.Column>
                        : null
                    }
                    {worker.managerStatus !== 'Worker'
                        ? <Grid.Column verticalAlign='bottom'>
                            <Link to="/workers">
                                <Button
                                    floated='right'
                                    color={normalColor}
                                    content='Workers'
                                    icon='address book'
                                />
                            </Link>
                        </Grid.Column>
                        : null
                    }
                </Grid.Row>
            </Grid>
        </Media >
    )

}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
    <MediaContextProvider>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}


const WorkPageLayout = () => {
    return (
        <ResponsiveContainer />
    )
}

export default WorkPageLayout