import axios from 'axios'

const url = process.env.REACT_APP_backend_url
// 'http://85.214.250.46:3001'
// 'http://localhost:3001'
// 'http://82.165.247.244:3001'

// --------------- Files ---------------
export const getInstallerDownloadLink = url + '/files/installers/' + process.env.REACT_APP_LATEST_INSTALLER
export const downloadInstaller = () =>
    axios({
        url: getInstallerDownloadLink,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'WorktimeTracker Setup.exe'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });


// --------------- Worker manipulation ---------------

export const loginWorker = (data, callback) => {
    axios({
        method: "POST",
        data: {
            username: data.email,
            password: data.password,
        },
        url: url + "/login"
    }).then((res) => {
        callback(res);
    }).catch((err, obj) => {
        if (err.message.includes('401'))
            callback(false, 'Wrong credentials')
        else
            callback(false, err.toString())
    })
}

export const loginWithWorkerAuthID = (data, callback) => {
    loginWorker(
        { email: data.personalId, password: data.personalId },
        callback
    )
}

export const getIsCompanyLocation = (callback) =>
    axios.get(url + '/login')
        .then((res) => callback(res.data))
        .catch(() => callback(false))


export const createWorker = (token, newWorker, callback) =>
    axios({
        method: "POST",
        data: newWorker,
        headers: { "Authorization": `Bearer ${token}` },
        url: url + '/add-worker'
    }).then((res, err) => {
        callback(res)
    }).catch((err) => {
        callback(false, err)
    })


export const modifyWorker = (token, newWorker, callback) =>
    axios({
        method: "POST",
        data: newWorker,
        headers: { "Authorization": `Bearer ${token}` },
        url: url + '/modify-worker'
    }).then(res => {
        callback(res)
    }).catch(err => {
        callback(false, err)
    })


export const checkLoggedIn = (token, callback) =>
    axios.get(url + '/login/verify', { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            callback(res)
        })
        .catch(err => {
            callback(false, err)
        })
// --------------- Worker Data ---------------

export const getWorker = (token, callback) =>
    axios.get(url + '/worker', { headers: { "Authorization": `Bearer ${token}` } })
        .then(res => {
            // console.log("Respond from /worker:", res.data)
            callback(res)
        })
        .catch((err) => {
            console.log('err:', err)
        });

export const getWorkers = (token, selectedLocation, page, limit, callback) =>
    axios({
        method: "POST",
        data: { selectedLocation },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + `/workers?page=${page}&limit=${limit}`
    }).then((res) => {
        callback(res.data)
    }).catch(err => {
        callback(false, err)
        console.log('Error on getWorkers:', err)
    })


export const getExactWorker = (token, personalId, callback) =>
    axios.get(url + `/worker/${personalId}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
            callback(res)
        })
        .catch((err) => {
            callback(false, err)
            // console.log('Error on getting exact worker:', err)
        })


export const getWorkerWorktime = (token, personalId, callback) =>
    axios.get(url + `/worker/worktime/${personalId}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
            callback(res)
        })
        .catch((err) => {
            callback(false, err)
            console.log('Error on getting exact worker worktime:', err)
        })

export const updateWorkerWorktimeOnDay = (token, workerPersonalId, originalDate, startTime, newTotalMinutes, callback) =>
    axios({
        method: "POST",
        data: { workerPersonalId, originalDate, newStartDate: startTime, newTotalMinutes },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/update-worker-worktime"
    }).then((res) => {
        callback(res)
    }).catch(err => {
        console.log('Error on login:', err)
    })


// --------------- Work State ---------------
export const setWorkingState = (token, workState) =>
    axios({
        method: "POST",
        data: { workState },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/set-work-state"
    }).then((res) => {
        console.log(`Result from setWorkingState: ${res.data}`)
    }).catch(err => {
        console.log('Error on setting working State:', err)
    })

// --------------- Company Settings ---------------

export const getCompanySettings = (token, callback) => {
    axios.get(url + '/company-settings', { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
            callback(res)
        })
        .catch((err) => {
            callback(false, err)
            console.log('Error on getting company settings:', err)
        })
}

export const addNewCompanyLocation = (token, newLocation, callback) =>
    axios({
        method: "POST",
        data: { newLocation },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/add-company-location"
    }).then((res) => {
        callback(res)
    }).catch(err => {
        console.log('Error on adding new company location:', err)
        callback(false, err)
    })

export const changeCompanySettings = (token, key, value, callback) =>
    axios({
        method: "POST",
        data: { key, value },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/change-company-settings"
    }).then((res) => {
        callback(res)
    }).catch(err => {
        console.log('Error on changing company settings:', err)
        callback(false, err)
    })

// --------------- Requests ---------------

export const createRequest = (token, data, callback) =>
    axios({
        method: "POST",
        data: data,
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/create-request"
    }).then((res) => {
        callback(res)
    }).catch(err => {
        console.log('Error on creating Request:', err)
        callback(false, err)
    })

export const getRequests = (token, approvalState, page, limit, callback) =>
    axios({
        method: "POST",
        data: { approvalState: approvalState },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + `/requests?page=${page}&limit=${limit}`
    }).then((res) => {
        callback(res.data)
    }).catch(err => {
        callback(false, err)
        console.log('Error on getRequests:', err)
    })

export const approveRequest = (token, objectId, callback) =>
    axios({
        method: "POST",
        data: { objectId },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/approve-request"
    }).then((res) => {
        callback(res.data)
    }).catch(err => {
        callback(false, err)
        console.log('Error on approveRequest:', err)
    })

export const rejectRequest = (token, objectId, callback) =>
    axios({
        method: "POST",
        data: { objectId },
        headers: { "Authorization": `Bearer ${token}` },
        url: url + "/reject-request"
    }).then((res) => {
        callback(res.data)
    }).catch(err => {
        callback(false, err)
        console.log('Error on rejectRequest:', err)
    })