import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import { Grid, Button, Form, TextArea, Dropdown, Message } from 'semantic-ui-react'

import * as api from '../api'

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { requestTypes } from '../utils/requestTypes';

const RequestPage = () => {
    const [requestType, setRequestType] = useState()
    const [initialDate, setInitialDate] = useState()
    const [finalDate, setFinalDate] = useState()
    const [comment, setComment] = useState()

    const [messageHidden, setMessageHidden] = useState(true)
    const [formFilled, setFormFilled] = useState(false)

    const onChangeInitialDate = (event, data) => setInitialDate(data.value);
    const onChangeFinalDate = (event, data) => setFinalDate(data.value);

    useEffect(() => {
        const datesAreCorrect = (finalDate - initialDate >= 0)
        setFormFilled(requestType && initialDate && finalDate && datesAreCorrect)
    }, [initialDate, finalDate, requestType])

    const createRequest = () => {
        const data = { requestType, initialDate, finalDate, comment: comment }
        api.createRequest(localStorage.getItem('token'), data, (result, err) => {
            if (result) {
                setMessageHidden(!messageHidden)
                setRequestType('')
                setInitialDate()
                setFinalDate()
                setComment('')
            } else 
                console.log('Error on sending request:', err)
        })
    }

    return (
        <div style={{ padding: '1em', height: '100vh' }}>
            <Link to="/work">
                <Button basic content='Back' icon='angle left' size='large' style={{ padding: '10px' }} color='yellow'/>
            </Link>
            <Grid id='grid-no-margin' textAlign='center' style={{ height: '90vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 250 }}>
                    <Form>
                        <Form.Field>
                            <Dropdown
                                style={{ maxWidth: 220, textAlign: 'center' }}
                                placeholder='Request type'
                                selection
                                options={requestTypes}
                                value={requestType}
                                onChange={(e, t) => { setRequestType(t.value) }}
                            />
                        </Form.Field>
                        <Form.Field>
                            <SemanticDatepicker
                                value={initialDate}
                                label='Initial date'
                                format='DD-MM-YYYY'
                                datePickerOnly
                                onChange={onChangeInitialDate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <SemanticDatepicker
                                value={finalDate}
                                label='Final date'
                                format='DD-MM-YYYY'
                                datePickerOnly
                                onChange={onChangeFinalDate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <TextArea
                                style={{ maxWidth: 220 }}
                                placeholder='Additional comments'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button
                                color='yellow'
                                basic
                                size='large'
                                content='Send a Request'
                                disabled={!formFilled}
                                onClick={createRequest}
                            />
                        </Form.Field>
                        <Message
                            hidden={messageHidden}
                            // color='green'
                            positive
                            header='Your request has been send'
                            content={initialDate && finalDate ?
                                `From ${initialDate ? initialDate.toDateString() : ''} to ${new Date(finalDate) ? finalDate.toDateString() : ''}` : ''}
                        />
                    </Form>
                </Grid.Column>
            </Grid>
        </div>
    )
}

export default RequestPage