import moment from "moment";

const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const getFullDay = (date) => {
    const start = new Date(date)
    start.setHours(5)
    start.setMinutes(0)

    const end = new Date(date)
    end.setHours(23)
    end.setMinutes(0)
    return { start, end }
}

export const workerWorkStatesToEvents = (wss, callback) => {
    var finalEvents = []
    var startWorktime = false
    var stopWorktime = false
    var allDay = false

    // Iterate over all actions, composing an events: { start, end, title }
    wss.forEach((item, index, array) => {
        startWorktime = item.started

        if (item.actionType === 'WORK' || item.actionType === 'STOP') {
            stopWorktime = moment(item.started).add(item.totalMinutes, "minute")
        } else if (item.actionType === 'SICK' || item.actionType === 'DAY-OFF') {
            stopWorktime = moment(item.started).add(1, "minute")
            allDay = true
        }
        const timeRepresentation = minutesToHMString(item.totalMinutes);
        var title = ''
        if (item.actionType === 'STOP') title = `Finished Work (${timeRepresentation})`
        else if (item.actionType === 'WORK') title = `Work in progress (${timeRepresentation} so far)`
        else title = capitalizeFirstLetter(item.actionType)

        const event = {
            id: finalEvents.length,
            start: new Date(startWorktime),
            end: new Date(stopWorktime),
            title: title,
            allDay: allDay
        }
        finalEvents.push(event)
        startWorktime = false
        stopWorktime = false
        allDay = false
    })
    callback(finalEvents)
}

function join(t, a, s) {
    function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(t);
    }
    return a.map(format).join(s);
}

export const requestsToEvents = (requests, callback) => {
    var finalEvents = []
    if (requests && Array.isArray(requests))
        requests.forEach((request, index, array) => {
            // Construct a title
            let a = [{ day: 'numeric' }, { month: 'short' }];
            const oneDayEvent = request.initialDate === request.finalDate
            let startDateFormatted = join(new Date(request.initialDate), a, '');
            var requestFinalDate = new Date(request.finalDate)
            requestFinalDate = moment(requestFinalDate).add(1, 'hour').toDate()
            let endDateFormatted = join(requestFinalDate, a, '');
            const title = oneDayEvent
                ? request.approvalState + " " + request.requestType + ' request (' + startDateFormatted + ")"
                : request.approvalState + " " + request.requestType + ' request (' + startDateFormatted + " - " + endDateFormatted + ")"

            // Create an event
            const event = {
                id: finalEvents.length,
                start: new Date(request.initialDate),
                end: requestFinalDate,
                title: title,
                allDay: true
            }
            finalEvents.push(event)
        })
    callback(finalEvents)
}

export const minutesToHMString = (minutes) => {
    if (minutes === 0) return '0 min'
    var negative = false
    if (minutes < 0) {
        negative = true
        minutes *= -1
    }
    var mins = Number(minutes);
    var h = Math.floor(mins / 60);
    var min = Math.floor(mins % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
    var mDisplay = min > 0 ? min + (min === 1 ? " min" : " mins") : "";
    return negative
        ? '- ' + hDisplay + mDisplay
        : hDisplay + mDisplay;
}

export const isNumber = (value) => {
    const regex = /^[0-9\b]+$/;
    return regex.test(value)
}


export const minutesBetween = (date1, date2) => {
    date1 = new Date(date1)
    date2 = new Date(date2)
    return parseInt(Math.abs(moment(date2, "hh:mm").toDate() - moment(date1, "hh:mm").toDate()) / (1000 * 60));
}
