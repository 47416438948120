import React, { useState, useEffect } from 'react'
import { Button, Form, Grid, Header, Icon, Message, Segment, Dimmer, Loader, Label, Divider } from 'semantic-ui-react'

import { useHistory } from 'react-router-dom'
import styles from '../styles';

import * as api from '../api/index';

const LoginPage = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [personalId, setPersonalId] = useState('')

    const [errorMessage, setErrorMessageText] = useState('')
    const [loading, setLoading] = useState(false)

    const [isCompanyLocation, setIsCompanyLocation] = useState(false)

    const history = useHistory()

    const mainColor = 'yellow'

    const setErrorMessage = (newText) => {
        setErrorMessageText(newText)
        setTimeout(() => {
            setErrorMessageText('')
        }, 5000)
    }

    const handleAuth = () => {
        if (personalId !== '') {
            setLoading(true)
            api.loginWithWorkerAuthID({ personalId }, (res, err) => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.token)
                    setLoading(false)
                    history.push('/work')
                } else {
                    setErrorMessage(err)
                    setLoading(false)
                }
            })

        } else if (email !== '' && password !== '') {
            setLoading(true)
            api.loginWorker({ email, password }, (res, err) => {
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.token)
                    setLoading(false)
                    history.push('/work')
                } else {
                    setErrorMessage(err)
                    setLoading(false)
                }
            })
        } else {
            setErrorMessage('Please provide credentials')
        }
    }

    const handleSignUp = () => {
        const token = localStorage.getItem('token');
        api.getWorker(token, (response) => {
            if (response.data && response.data.email)
                console.log('IM a WORKER!!! with email: ', response.data.email)
            else
                console.log('Worker is empty :(.', response)
        })
    }

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            api.checkLoggedIn(token, (response) => {
                if (response) {
                    setLoading(false)
                    history.push('/work')
                } else {
                    api.getIsCompanyLocation((isCompanyIP) => setIsCompanyLocation(isCompanyIP))
                }
            })
        } else {
            api.getIsCompanyLocation((isCompanyIP) => setIsCompanyLocation(isCompanyIP))
        }
    }, [])

    return (
        <div>
            <Grid id='grid-no-margin' textAlign='center' style={styles.fullscreen_grid} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    {/* <Dimmer active={loading} inverted>
                        <Loader>Loading</Loader>
                    </Dimmer> */}
                    <Header as='h2' color={mainColor} textAlign='center'>
                        <Icon name='hand victory' /> Log-in to your account
                    </Header>
                    <Form size='large' loading={loading}>
                        <Segment stacked>
                            <Form.Input
                                fluid icon='mail'
                                iconPosition='left'
                                placeholder='E-mail address'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            {isCompanyLocation
                                ? <div>
                                    <Divider horizontal>Or</Divider>
                                    <div style={{ paddingTop: '.8em' }} />
                                    <Form.Input
                                        fluid icon='user'
                                        iconPosition='left'
                                        placeholder='Personal Code'
                                        onChange={(e) => setPersonalId(e.target.value)}
                                    />
                                    <div style={{ paddingTop: '.4em' }} />
                                </div>
                                : <div />
                            }

                            <Button content='Login' color={mainColor} fluid size='large' onClick={handleAuth} />
                        </Segment>
                    </Form>

                    {/* <Message>
                        <Button as='div' labelPosition='left'>
                            <Label color='yellow' basic pointing='right'>
                                New to us?
                            </Label>
                            <Button onClick={handleSignUp}>
                                Sign Up
                            </Button>
                        </Button> */}
                        {/* <Header color='olive' size='small'>
                            New to us?
                            <Button onClick={handleSignUp}>
                                Sign Up
                            </Button>
                        </Header> */}

                    {/* </Message> */}
                    <Message negative hidden={errorMessage === ''}>
                        {errorMessage}
                    </Message>
                </Grid.Column>
            </Grid>
        </div >
    )

}

export default LoginPage