export const approvalState = [
    {
        key: 'Wait',
        text: 'Wait',
        value: 'Wait',
        icon: 'wait',
    },
    {
        key: 'Approved',
        text: 'Approved',
        value: 'Approved',
        icon: 'check circle outline'
    },
    {
        key: 'Rejected',
        text: 'Rejected',
        value: 'Rejected',
        icon: 'remove'
    },
]