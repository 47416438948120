import React, { useEffect, useState } from 'react'
import { Button, Modal, Grid, Label, Header } from 'semantic-ui-react'
import { Link, useParams } from "react-router-dom";

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import * as api from '../api'

import { minutesToHMString, workerWorkStatesToEvents, minutesBetween, requestsToEvents } from '../utils'

import TimePicker from 'react-time-picker'

const localizer = momentLocalizer(moment)

const WorkerWorktime = (props) => {
    const [worker, setWorker] = useState({});
    const [requests, setRequests] = useState({});
    const [events, setEvents] = useState([])

    const [originalMinutes, setOriginalMinutes] = useState(0)
    const [originalDate, setOriginalDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [totalMinutes, setTotalMinutes] = useState(0)
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false)

    let { workerPersonalId } = useParams();

    const colors = {
        workFinish: '#FF9B42',
        workProgress: '#85CB33',
        sick: '#A188A6',
        dayOff: '#B7D1DA'
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = colors.workFinish
        if (event.title.toLowerCase().includes('sick'))
            backgroundColor = colors.sick
        else if (event.title.toLowerCase().includes('day-off'))
            backgroundColor = colors.dayOff
        else if (event.title.toLowerCase().includes('progress'))
            backgroundColor = colors.workProgress

        var style = {
            backgroundColor: backgroundColor,
            color: 'black',
        };
        return {
            style: style
        };
    }

    const updateWorktimeEvents = () => {
        api.getWorkerWorktime(localStorage.getItem('token'), workerPersonalId, (response) => {
            if (!response) return
            setWorker(response.data.worker)
            setRequests(response.data.requests)
        })
    }

    useEffect(() => updateWorktimeEvents(), [workerPersonalId])

    useEffect(() => {
        var resultArray = []
        if (worker && worker.workStates)
            workerWorkStatesToEvents(worker.workStates, (eventsFromDB) =>
                resultArray = resultArray.concat(eventsFromDB))
        if (requests)
            requestsToEvents(requests, (eventsFromRequests) => resultArray =
                resultArray.concat(eventsFromRequests))
        setEvents(resultArray)
    }, [worker, requests])

    useEffect(() => {
        if (startTime && endTime) {
            const timeIsRight = startTime < endTime
            if (timeIsRight) {
                const newTotalMinutes = minutesBetween(moment(startTime, "hh:mm").toDate(), moment(endTime, "hh:mm").toDate())
                setTotalMinutes(newTotalMinutes)
            }
            setConfirmButtonDisabled(!timeIsRight)
        }
    }, [startTime, endTime])

    const handleDoubleClick = (e) => {
        if (!e.allDay) {
            setOriginalDate(e.start)
            setOriginalMinutes(minutesBetween(e.end, e.start))
            setStartTime(e.start)
            setEndTime(e.end)
            setIsModalOpened(true)
        }
    }



    return (
        <div style={{ height: '100vh' }}>
            <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={events}
                eventPropGetter={eventStyleGetter}
                style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    height: '100vh',
                }}
                onDoubleClickEvent={(e) => { handleDoubleClick(e) }}
            />

            <Modal open={isModalOpened} dimmer='inverted' size='tiny' >
                <Modal.Header>Changing worktime</Modal.Header>
                <Modal.Content>
                    <Grid container columns={2} textAlign='center'>
                        <Grid.Row>
                            <Grid.Column>
                                <Header size='small'>Total worktime: </Header>{minutesToHMString(totalMinutes)}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Label color='yellow' basic pointing='right' content='Start Time' />
                                <TimePicker
                                    value={startTime}
                                    onChange={e => {
                                        if (e) {
                                            const h = e.split(':')[0]
                                            const m = e.split(':')[1]
                                            var newStartTime = new Date(startTime.setHours(parseInt(h)))
                                            newStartTime = new Date(newStartTime.setMinutes(parseInt(m)))
                                            setStartTime(newStartTime)
                                        }
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <TimePicker
                                    value={endTime}
                                    onChange={e => {
                                        if (e) {
                                            const h = e.split(':')[0]
                                            const m = e.split(':')[1]
                                            var newEndTime = new Date(endTime.setHours(parseInt(h)))
                                            newEndTime = new Date(newEndTime.setMinutes(parseInt(m)))
                                            setEndTime(newEndTime)
                                        }
                                    }}
                                />
                                <Label color='yellow' basic pointing='left' content='End Time' />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setIsModalOpened(false)}>
                        Cancel
                    </Button>
                    <Button
                        content="Confirm time saving"
                        labelPosition='right'
                        icon='checkmark'
                        disabled={confirmButtonDisabled}
                        onClick={() => {
                            if (totalMinutes !== originalMinutes) {
                                const token = localStorage.getItem('token');
                                api.updateWorkerWorktimeOnDay(token, workerPersonalId, originalDate, startTime, totalMinutes,
                                    (res) => updateWorktimeEvents())
                                setIsModalOpened(false)
                            }
                        }}
                        positive
                    />
                </Modal.Actions>
            </Modal>

            <div style={{ position: 'fixed', left: '1em', bottom: '1em' }}>
                <Link to="/workers">
                    <Button content='Back to List' icon='angle left' size='large' />
                </Link>
            </div>
        </div>
    )
}

export default WorkerWorktime