import React, { useState, useEffect } from 'react'
import { Grid, Form, Icon, Header, Table, Select, Button, Divider, Label, Pagination } from 'semantic-ui-react'

import * as api from '../api'
import { Link } from 'react-router-dom'

import { approvalState } from '../utils/approvalState.js'

const RequestsPage = () => {
    const [selectedApprovalState, setSelectedApprovalState] = useState(approvalState[0].value)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [requests, setRequests] = useState()

    useEffect(() => {
        reloadRequests()
    }, [selectedApprovalState, page])

    const handlePageChange = (e, { activePage }) => setPage(activePage)

    const reloadRequests = () => {
        const limit = 5
        api.getRequests(localStorage.getItem('token'), selectedApprovalState, page, limit,
            (response) => {
                if (!response) return
                const { results, next } = response
                const totalPages = next ? Math.max(1, next.max) : page
                setTotalPages(totalPages)
                setRequests(results)
            })
    }

    const approveRequest = (objectId) => {
        api.approveRequest(localStorage.getItem('token'), objectId, (res) => {
            console.log('Res from approveRequest:', res)
            reloadRequests()
        })
    }

    const rejectRequest = (objectId) => {
        api.rejectRequest(localStorage.getItem('token'), objectId, (res) => {
            console.log('Res from rejectRequest:', res)
            reloadRequests()
        })
    }

    return (
        <div id='grid-table'>
            <Link to="/work">
                <Button basic content='Back' icon='angle left' size='large' color='yellow' />
            </Link>
            <Grid textAlign='center' verticalAlign='middle'>
                <Form>
                    <Header as='h2' icon color='yellow'>
                        <Icon name='file alternate' />
                        <Header.Content>Requests Status</Header.Content>
                    </Header>

                    <Form.Field
                        control={Select}
                        options={approvalState}
                        value={selectedApprovalState}
                        onChange={(e, rs) => setSelectedApprovalState(rs.value)}
                        placeholder='Requests status'
                    />
                </Form>
            </Grid>
            <br />
            <Divider />

            {requests && requests.length !== 0
                ?
                <Table celled color='yellow' unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Person Email</Table.HeaderCell>
                            <Table.HeaderCell>Request type</Table.HeaderCell>
                            <Table.HeaderCell>Start date</Table.HeaderCell>
                            <Table.HeaderCell>Finish date</Table.HeaderCell>
                            <Table.HeaderCell>Comment</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {requests
                            ? requests.map((request) => (
                                <Table.Row key={request._id}>
                                    <Table.Cell>
                                        <Label>{request.email}</Label>
                                    </Table.Cell>
                                    <Table.Cell>{request.requestType}</Table.Cell>
                                    <Table.Cell>{new Date(request.initialDate).toDateString()}</Table.Cell>
                                    <Table.Cell>{new Date(request.finalDate).toDateString()}</Table.Cell>
                                    <Table.Cell>{request.comment}</Table.Cell>
                                    <Table.Cell>
                                        {request.approvalState !== approvalState[1].value // Not Approve
                                            ? <Button basic color='green' content='Approve' onClick={() => approveRequest(request._id)} />
                                            : null}
                                        {request.approvalState === approvalState[0].value // Wait
                                            ? <Button basic color='red' content='Reject' onClick={() => rejectRequest(request._id)} />
                                            : null}
                                        {request.approvalState === approvalState[1].value
                                            ? <Header content='Already approved' color='green' size='small' />
                                            : null
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            ))
                            : console.log('requests:', requests)
                        }
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6'>
                                <Pagination
                                    floated='right'
                                    totalPages={totalPages}
                                    type='range'
                                    activePage={page}
                                    onPageChange={handlePageChange}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                : <Header color='yellow' textAlign='center' content='No Requests found for that filter' />
            }
        </div>
    )
}

export default RequestsPage