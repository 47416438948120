import React, { useState, useEffect } from 'react'
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import { Link, useParams } from "react-router-dom";

import {
    Button,
    Checkbox,
    Divider,
    Grid,
    Header,
    Segment,
    Select,
    Form,
    Message,
    Modal,
    Container
} from 'semantic-ui-react'
import * as api from '../api/index';
import { isNumber } from '../utils';


const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})


// If in url personalId detected- it's a worker modification. Not detected-creating new worker.
// After filling all required fields (checked by checkFields()), newWorker constant is filled 
// and send by finallySendNewWorker()
const DesktopContainer = (props) => {
    let { workerPersonalId } = useParams();

    const [newWorker, setNewWorker] = useState('')

    const [personalId, setPersonalId] = useState()
    const [authId, setAuthId] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [postcode, setPostcode] = useState()
    const [city, setCity] = useState()
    const [street, setStreet] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [holidayApproval, setIsHolidayApproval] = useState(true)
    const [privatePhone, setPrivatePhone] = useState()
    const [officePhone, setOfficePhone] = useState()
    const [holidaysCount, setHolidaysCount] = useState(60)
    const [companyLocations, setCompanyLocations] = useState([])
    const [selectedCompanyLocations, setSelectedCompanyLocations] = useState([])

    const [targetTimeMonday, setTargetTimeMonday] = useState()
    const [targetTimeTuesday, setTargetTimeTuesday] = useState()
    const [targetTimeWednesday, setTargetTimeWednesday] = useState()
    const [targetTimeThursday, setTargetTimeThursday] = useState()
    const [targetTimeFriday, setTargetTimeFriday] = useState()
    const [targetTimeSaturday, setTargetTimeSaturday] = useState()
    const [targetTimeSunday, setTargetTimeSunday] = useState()

    const [timeModelGeneral, setGeneralTimeModel] = useState('BAG-hour')
    const [timeModelMonday, setTimeModelMonday] = useState('BAG-hour')
    const [timeModelTuesday, setTimeModelTuesday] = useState('BAG-hour')
    const [timeModelWednesday, setTimeModelWednesday] = useState('BAG-hour')
    const [timeModelThursday, setTimeModelThursday] = useState('BAG-hour')
    const [timeModelFriday, setTimeModelFriday] = useState('BAG-hour')
    const [timeModelSaturday, setTimeModelSaturday] = useState('BAG-hour')
    const [timeModelSunday, setTimeModelSunday] = useState('BAG-hour')

    const [dailyWorkingTime, setDailyWorkingTime] = useState(0)
    const [weeklyWorkingTime, setWeeklyWorkingTime] = useState(0)
    const [monthlyWorkingTime, setMonthlyWorkingTime] = useState(0)
    const [annualWorkingTime, setAnnualWorkingTime] = useState(0)

    const [holidaysCountCorrect, setHolidaysCountCorrect] = useState(true)
    const [personalIdCorrect, setPersonalIdCorrect] = useState(true)
    const [authIdCorrect, setAuthIdCorrect] = useState(true)
    const [emailCorrect, setEmailCorrect] = useState(true)
    const [passwordCorrect, setPasswordCorrect] = useState(true)
    const [firstNameCorrect, setFirstNameCorrect] = useState(true)
    const [lastNameCorrect, setLastNameCorrect] = useState(true)
    const [timeModelCorrect, setTimeModelCorrect] = useState(true)

    const [selectedLocationsCorrect, setSelectedLocationsCorrect] = useState(true)

    const [toShowFinalMessage, setShowFinalMessage] = useState(false)
    const [finalMessage, setFinalMessage] = useState('')
    const [responseCodeMessage, setResponseCodeMessage] = useState(-1)
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
    const [dataErrorMessageHidden, setDataErrorMessageHidden] = useState(true)

    useEffect(() => {
        api.getCompanySettings(localStorage.getItem('token'), (res) => {
            if (!res) return
            const defaultHolidaysCount = res.data.general.defaultHolidaysCount
            setHolidaysCount(parseInt(defaultHolidaysCount))
            const compLocsFromDB = res.data.admin.companyLocations
            var compLocationsObjectsArr = []
            for (let i = 0; i < compLocsFromDB.length; i++) {
                const companyLocation = compLocsFromDB[i];
                compLocationsObjectsArr.push({ key: companyLocation, text: companyLocation, value: companyLocation })
            }
            setCompanyLocations(compLocationsObjectsArr)
        })
    }, [])

    useEffect(() => {
        if (workerPersonalId) {
            api.getExactWorker(localStorage.getItem('token'), workerPersonalId, (res) => {
                if (res && res.data) {
                    const workerToModify = res.data
                    console.log('ExactWorker workerToModify:', workerToModify)
                    setPersonalId(workerToModify.personalId)
                    setEmail(workerToModify.email)
                    setPassword('')
                    setFirstName(workerToModify.firstName)
                    setLastName(workerToModify.lastName)
                    setPostcode(workerToModify.address.postcode)
                    setCity(workerToModify.address.city)
                    setStreet(workerToModify.address.street)
                    setPrivatePhone(workerToModify.contactInfo.privatePhone)
                    setOfficePhone(workerToModify.contactInfo.officePhone)
                    setIsAdmin(workerToModify.managerStatus === 'Master-User' || workerToModify.managerStatus === 'HR')
                    setShowFinalMessage(false)
                    setIsHolidayApproval(workerToModify.holidayApproval)
                    setAuthId(workerToModify.authId)
                    
                    setGeneralTimeModel(workerToModify.timeModel.general)
                    setTimeModelMonday(workerToModify.timeModel.monday)
                    setTimeModelTuesday(workerToModify.timeModel.tuesday)
                    setTimeModelWednesday(workerToModify.timeModel.wednesday)
                    setTimeModelThursday(workerToModify.timeModel.thursday)
                    setTimeModelFriday(workerToModify.timeModel.friday)
                    setTimeModelSaturday(workerToModify.timeModel.saturday)
                    setTimeModelSunday(workerToModify.timeModel.sunday)

                    setDailyWorkingTime(workerToModify.workingTime.daily)
                    setWeeklyWorkingTime(workerToModify.workingTime.weekly)
                    setMonthlyWorkingTime(workerToModify.workingTime.monthly)
                    setAnnualWorkingTime(workerToModify.workingTime.annual)

                    setTargetTimeMonday(workerToModify.targetTime.monday)
                    setTargetTimeTuesday(workerToModify.targetTime.tuesday)
                    setTargetTimeWednesday(workerToModify.targetTime.wednesday)
                    setTargetTimeThursday(workerToModify.targetTime.thursday)
                    setTargetTimeFriday(workerToModify.targetTime.friday)
                    setTargetTimeSaturday(workerToModify.targetTime.saturday)
                    setTargetTimeSunday(workerToModify.targetTime.sunday)

                    setHolidaysCount(parseInt(workerToModify.totalHolidays))
                    setSelectedCompanyLocations(workerToModify.companyLocations)
                }
            })
        }
    }, [workerPersonalId])

    const toggleIsAdmin = () => setIsAdmin(!isAdmin)
    const toggleHolidayApproval = () => setIsHolidayApproval(!holidayApproval)

    const finishAddingOnUI = () => {
        setPersonalId('')
        setEmail('')
        setPassword('')
        setFirstName('')
        setLastName('')
        setPostcode('')
        setCity('')
        setStreet('')
        setPrivatePhone('')
        setOfficePhone('')
        setIsAdmin(false)
        setShowFinalMessage(true)
        setIsHolidayApproval(false)
        setAuthId('')
        setGeneralTimeModel('BAG-hour')
        setTimeModelMonday('BAG-hour')
        setTimeModelTuesday('BAG-hour')
        setTimeModelWednesday('BAG-hour')
        setTimeModelThursday('BAG-hour')
        setTimeModelFriday('BAG-hour')
        setTimeModelSaturday('BAG-hour')
        setTimeModelSunday('BAG-hour')
        setDailyWorkingTime(0)
        setWeeklyWorkingTime(0)
        setMonthlyWorkingTime(0)
        setAnnualWorkingTime(0)
        setTargetTimeMonday(0)
        setTargetTimeTuesday(0)
        setTargetTimeWednesday(0)
        setTargetTimeThursday(0)
        setTargetTimeFriday(0)
        setTargetTimeSaturday(0)
        setTargetTimeSunday(0)
        setHolidaysCount(30)
        setSelectedCompanyLocations([])
    }

    const checkFields = () => {
        // Create local isCorrect values 
        const holidaysCountCorrectLocal = isNumber(holidaysCount)
        const authIdCorrectLocal = workerPersonalId || (authId && authId !== '')
        const personalIdCorrectLocal = workerPersonalId || personalId !== ''
        const emailCorrectLocal = workerPersonalId || (email && email !== '')
        const passwordCorrectLocal = workerPersonalId || (password && password !== '' && password.length >= 8)
        const firstNameCorrectLocal = firstName !== ''
        const lastNameCorrectLocal = lastName !== ''
        const timeModelGeneralCorrectLocal = timeModelGeneral !== 'nothing'
        const selectedLocationsCorrectLocal = selectedCompanyLocations && selectedCompanyLocations.length
        // Set them to component's isCorrect values
        setHolidaysCountCorrect(holidaysCountCorrect)
        setPersonalIdCorrect(personalIdCorrect)
        setAuthIdCorrect(authIdCorrect)
        setEmailCorrect(emailCorrect)
        setPasswordCorrect(passwordCorrect)
        setFirstNameCorrect()
        setLastNameCorrect(lastName !== '')
        setTimeModelCorrect(timeModelGeneral !== 'nothing')
        setSelectedLocationsCorrect(selectedLocationsCorrectLocal)
        // Form a compound result of all fields corectness 
        const result = holidaysCountCorrectLocal
            && personalIdCorrectLocal
            && authIdCorrectLocal
            && emailCorrectLocal
            && passwordCorrectLocal
            && firstNameCorrectLocal
            && lastNameCorrectLocal
            && timeModelGeneralCorrectLocal
            && selectedLocationsCorrectLocal
        setDataErrorMessageHidden(Boolean(result))
        return result
    }

    const timeModelOptions = [
        { key: 'nothing', text: '', value: 'nothing' },
        { key: 'bag-hour', text: 'BAG-hour', value: 'BAG-hour' },
        { key: 'flextime', text: 'Flexible working hours', value: 'Flexible working hours' },
        { key: 'flexitime without breaks', text: 'Flextime without breaks', value: 'Flextime without breaks' },
        { key: 'flextime TZ', text: 'Flexible working hours TZ', value: 'Flexible working hours TZ' },
        { key: 'NurUrlaub', text: 'Nur Urlaub', value: 'Nur Urlaub' },
    ]

    const dayModelOptions = [
        { key: 'nothing', text: '', value: 'nothing' },
        { key: 'BAG Hour', text: 'BAG Hour', value: 'BAG Hour' },
        { key: 'GLZ', text: 'GLZ', value: 'GLZ' },
        { key: 'GLZ Fr.', text: 'GLZ Fr.', value: 'GLZ Fr.' },
        { key: 'GLZ Fr. ohne Pausen', text: 'GLZ Fr. ohne Pausen', value: 'GLZ Fr. ohne Pausen' },
        { key: 'GLZ ohne Pausen', text: 'GLZ ohne Pausen', value: 'GLZ ohne Pausen' },
        { key: 'Kein Urlaub', text: 'Kein Urlaub', value: 'Kein Urlaub' },
        { key: 'Nur Urlaub', text: 'Nur Urlaub', value: 'Nur Urlaub' },
    ]

    const addWorker = async () => {
        const newWorkerObject = {
            personalId: personalId,
            managerStatus: isAdmin ? 'HR' : 'Worker',
            firstName: firstName, lastName: lastName,
            email: email, password: password, authId: authId,
            holidayApproval: holidayApproval,
            totalHolidays: parseInt(holidaysCount),
            saldo: 0,
            workingTime: {
                daily: dailyWorkingTime,
                weekly: weeklyWorkingTime,
                monthly: monthlyWorkingTime,
                annual: annualWorkingTime,
            },
            address: { postcode, street, city },
            contactInfo: { privatePhone, officePhone },
            timeModel: {
                general: timeModelGeneral,
                monday: timeModelMonday,
                tuesday: timeModelTuesday,
                wednesday: timeModelWednesday,
                thursday: timeModelThursday,
                friday: timeModelFriday,
                saturday: timeModelSaturday,
                sunday: timeModelSunday
            },
            targetTime: {
                monday: targetTimeMonday,
                tuesday: targetTimeTuesday,
                wednesday: targetTimeWednesday,
                thursday: targetTimeThursday,
                friday: targetTimeFriday,
                saturday: targetTimeSaturday,
                sunday: targetTimeSunday
            },
            companyLocations: selectedCompanyLocations
        }
        setNewWorker(newWorkerObject)
        const allFieldsAreCorrect = checkFields()
        if (allFieldsAreCorrect) setConfirmationModalOpen(true)
    }

    const finallySendNewWorker = async () => {
        const token = localStorage.getItem('token');
        if (workerPersonalId) {
            await api.modifyWorker(token, newWorker, (res, err) => {
                console.log('Response at modifyWorker:', res)
                if (res) {
                    setFinalMessage(res.data)
                    setResponseCodeMessage(res.status)
                    finishAddingOnUI()
                    setTimeout(() => {
                        setShowFinalMessage(false)
                    }, 5000);
                }
            })
        } else {
            await api.createWorker(token, newWorker, (res, err) => {
                console.log('Response at createWorker, err:', res, err)
                if (res) {
                    setFinalMessage(res.data)
                    setResponseCodeMessage(res.status)
                    finishAddingOnUI()
                    setTimeout(() => {
                        setShowFinalMessage(false)
                    }, 5000);
                } else if (JSON.stringify(err).includes('409')) {
                    setFinalMessage('User Already Exists')
                    setResponseCodeMessage(409)
                    finishAddingOnUI()
                    setTimeout(() => {
                        setShowFinalMessage(false)
                    }, 5000);
                } else {
                    // No 409 status code found: problem not related with worker already in DB
                    setFinalMessage('Please contact administrator')
                    finishAddingOnUI()
                    setTimeout(() => {
                        setShowFinalMessage(false)
                    }, 5000);
                }
            })
        }
    }

    const createWorkerModalScreen = () => (
        <Modal
            onClose={() => setConfirmationModalOpen(false)}
            onOpen={() => setConfirmationModalOpen(true)}
            open={confirmationModalOpen}
            trigger={<div />} >
            <Modal.Header>Confirm worker creation</Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <Header>New worker data</Header>
                    <Container>
                        <pre>{JSON.stringify(newWorker, undefined, ' ')}</pre>
                    </Container>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setConfirmationModalOpen(false)}>
                    Nope
                </Button>
                <Button
                    content="Confirming worker creation"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => {
                        setConfirmationModalOpen(false)
                        finallySendNewWorker()
                    }}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )


    return (
        <Media greaterThan='mobile' >
            <div style={{ padding: '1em', height: '100vh' }}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Link to='/workers' >
                                <Button basic content='Back' icon='angle left' size='large' color='yellow' />
                            </Link>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='center'>
                            {toShowFinalMessage
                                ? <Message
                                    positive={responseCodeMessage === 200}
                                    negative={responseCodeMessage !== 200}
                                    header={responseCodeMessage === 200 ? finalMessage : `${responseCodeMessage}: ${finalMessage}`}
                                />
                                : <Header textAlign='center' color='orange'>More fields are needed? Contact us</Header>
                            }
                            <Message
                                hidden={dataErrorMessageHidden}
                                error
                                header='Attention'
                                content='Please make sure that all red fields are filled properly'
                            />
                        </Grid.Column>
                        <Grid.Column width={6} floated='right'>
                            {createWorkerModalScreen()}
                            <Button floated='right' color='yellow' onClick={addWorker}>{
                                workerPersonalId ? 'Modify worker' : 'Add a Worker'
                            }</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div style={{ padding: '2vh' }}>
                    <Segment placeholder>
                        <Grid verticalAlign='middle' textAlign='center' container columns={2}>
                            <Grid.Column style={{ maxWidth: '50%' }}>
                                <Form size={'small'}>
                                    <Form.Group unstackable widths={2}>
                                        <Form.Input required={!workerPersonalId} label='Personal number' placeholder='Personal number'
                                            name='personalNumber'
                                            disabled={!!workerPersonalId}
                                            value={personalId}
                                            error={!personalIdCorrect}
                                            onChange={e => { setPersonalId(e.target.value); setPersonalIdCorrect(true) }}
                                        />
                                    </Form.Group>

                                    <Form.Group unstackable widths={2}>
                                        <Form.Input required label='First name' placeholder='First name'
                                            name='firstName'
                                            value={firstName}
                                            error={!firstNameCorrect}
                                            onChange={e => { setFirstName(e.target.value); setFirstNameCorrect(true) }}
                                        />
                                        <Form.Input required label='Last name' placeholder='Last name'
                                            name='lastName'
                                            value={lastName}
                                            error={!lastNameCorrect}
                                            onChange={e => { setLastName(e.target.value); setLastNameCorrect(true) }}
                                        />
                                    </Form.Group>

                                    <Divider />

                                    <Form.Group widths={2}>
                                        <Form.Input label='Postcode' placeholder='Postcode'
                                            name='postcode'
                                            value={postcode}
                                            onChange={e => setPostcode(e.target.value)}
                                        />
                                        <Form.Input label='City' placeholder='City'
                                            name='city'
                                            value={city}
                                            onChange={e => setCity(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input label='Street' placeholder='Street'
                                            name='street'
                                            value={street}
                                            onChange={e => setStreet(e.target.value)}
                                        />
                                    </Form.Group>

                                    <Divider />

                                    <Form.Group unstackable widths={2}>
                                        <Form.Input required label='Email' placeholder='Email'
                                            // name='email'
                                            value={email}
                                            error={!emailCorrect}
                                            onChange={e => { setEmail(e.target.value); setEmailCorrect(true) }}
                                        />
                                        <Form.Input required label='Password' placeholder='Password'
                                            // type='password'
                                            // name='password'
                                            value={password}
                                            error={!passwordCorrect}
                                            onChange={e => { setPassword(e.target.value); setPasswordCorrect(true) }}
                                        />
                                    </Form.Group>
                                    <Form.Group unstackable widths={2}>
                                        <Form.Input required label='Auth Id' placeholder='Auth Id'
                                            name='Auth Id'
                                            value={authId}
                                            error={!authIdCorrect}
                                            onChange={e => { setAuthId(e.target.value); setAuthIdCorrect(true) }}
                                        />
                                    </Form.Group>

                                    <Divider />

                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Private phone'
                                            placeholder='Private phone'
                                            value={privatePhone}
                                            onChange={e => setPrivatePhone(e.target.value)}
                                        />
                                        <Form.Input
                                            label='Office phone'
                                            placeholder='Office phone'
                                            value={officePhone}
                                            onChange={e => setOfficePhone(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Divider />
                                    <Form.Group unstackable widths={2}>
                                        <Form.Input
                                            label='Daily working time (min)'
                                            placeholder='Daily working time'
                                            value={dailyWorkingTime}
                                            onChange={e => setDailyWorkingTime(e.target.value)} />
                                        <Form.Input
                                            label='Weekly working time (min)'
                                            placeholder='Weekly working time'
                                            value={weeklyWorkingTime}
                                            onChange={e => setWeeklyWorkingTime(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group unstackable widths={2}>
                                        <Form.Input
                                            label='Monthly working time (min)'
                                            placeholder='Monthly working time'
                                            value={monthlyWorkingTime}
                                            onChange={e => setMonthlyWorkingTime(e.target.value)} />
                                        <Form.Input
                                            label='Annual working time (min)'
                                            placeholder='Annual working time'
                                            value={annualWorkingTime}
                                            onChange={e => setAnnualWorkingTime(e.target.value)} />
                                    </Form.Group>
                                </Form>
                            </Grid.Column>


                            <Grid.Column>
                                <Form size={'small'}>
                                    <Form.Group widths={2}>
                                        <Form.Field
                                            required
                                            control={Select}
                                            options={timeModelOptions}
                                            label={{ children: 'Time Model', htmlFor: 'form-select-control-time-model' }}
                                            placeholder='Time Model'
                                            value={timeModelGeneral}
                                            error={!timeModelCorrect}
                                            onChange={(e, rs) => { setGeneralTimeModel(rs.value); setTimeModelCorrect(true) }} />
                                        <Form.Input
                                            label='Holiday count'
                                            placeholder={30}
                                            value={holidaysCount}
                                            error={!holidaysCountCorrect}
                                            onChange={e => { setHolidaysCount(e.target.value); setHolidaysCountCorrect(true) }}
                                        />
                                    </Form.Group>
                                    <Divider />

                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Mon (min)'
                                            placeholder='Target Time Mon'
                                            value={targetTimeMonday}
                                            onChange={(e, rs) => setTargetTimeMonday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Mon', htmlFor: 'form-select-control-day-model-mon' }}
                                            placeholder='Day model Mon'
                                            value={timeModelMonday}
                                            onChange={(e, rs) => setTimeModelMonday(rs.value)} />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Tue (min)'
                                            placeholder='Target Time Tue'
                                            value={targetTimeTuesday}
                                            onChange={(e, rs) => setTargetTimeTuesday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Tue', htmlFor: 'form-select-control-day-model-tue' }}
                                            placeholder='Day model Tue'
                                            value={timeModelTuesday}
                                            onChange={(e, rs) => setTimeModelTuesday(rs.value)} />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Wed (min)'
                                            placeholder='Target Time Wed'
                                            value={targetTimeWednesday}
                                            onChange={(e, rs) => setTargetTimeWednesday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Wed', htmlFor: 'form-select-control-day-model-wed' }}
                                            placeholder='Day model Wed'
                                            value={timeModelWednesday}
                                            onChange={(e, rs) => setTimeModelWednesday(rs.value)} />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Thu (min)'
                                            placeholder='Target Time Thu'
                                            value={targetTimeThursday}
                                            onChange={(e, rs) => setTargetTimeThursday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Thu', htmlFor: 'form-select-control-day-model-thu' }}
                                            placeholder='Day model Thu'
                                            value={timeModelThursday}
                                            onChange={(e, rs) => setTimeModelThursday(rs.value)} />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Fri (min)'
                                            placeholder='Target Time Fri'
                                            value={targetTimeFriday}
                                            onChange={(e, rs) => setTargetTimeFriday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Fri', htmlFor: 'form-select-control-day-model-fri' }}
                                            placeholder='Day model Fri'
                                            value={timeModelFriday}
                                            onChange={(e, rs) => setTimeModelFriday(rs.value)} />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Sat (min)'
                                            placeholder='Target Time Sat'
                                            value={targetTimeSaturday}
                                            onChange={(e, rs) => setTargetTimeSaturday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Sat', htmlFor: 'form-select-control-day-model-sat' }}
                                            placeholder='Day model Sat'
                                            value={timeModelSaturday}
                                            onChange={(e, rs) => setTimeModelSaturday(rs.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Input
                                            label='Target Time Sun (min)'
                                            placeholder='Target Time Sun'
                                            value={targetTimeSunday}
                                            onChange={(e, rs) => setTargetTimeSunday(rs.value)} />
                                        <Form.Field
                                            control={Select}
                                            options={dayModelOptions}
                                            label={{ children: 'Day model Sun', htmlFor: 'form-select-control-day-model-sun' }}
                                            placeholder='Day model Sun'
                                            value={timeModelSunday}
                                            onChange={(e, rs) => setTimeModelSunday(rs.value)} />
                                    </Form.Group>

                                    <Divider />
                                    <Form.Group widths={2}>
                                        <Form.Dropdown
                                            multiple
                                            search
                                            selection
                                            error={!selectedLocationsCorrect}
                                            onChange={(e, { value }) => { 
                                                setSelectedCompanyLocations(value); 
                                                setSelectedLocationsCorrect(value !== [])
                                            }}
                                            value={selectedCompanyLocations}
                                            placeholder='Company locations'
                                            options={companyLocations}
                                        />
                                    </Form.Group>
                                    <Form.Group widths={2}>
                                        <Form.Field>
                                            <Checkbox
                                                label='Holiday Approval'
                                                onChange={toggleHolidayApproval}
                                                checked={holidayApproval} />
                                        </Form.Field>
                                        <Form.Field>
                                            <Checkbox
                                                label='Can add/change workers'
                                                onChange={toggleIsAdmin}
                                                checked={isAdmin} />
                                        </Form.Field>
                                    </Form.Group>

                                </Form>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </div>
                {/* <Divider /> */}
            </div>
        </Media>
    )

}

DesktopContainer.propTypes = {
    children: PropTypes.node,
}

const MobileContainer = (props) => {

    return (
        <Media at='mobile'>
            <div style={{ padding: '1vh' }}>
                <Link to='workers'>
                    <Button basic content='Back' icon='angle left' size='large' style={{ padding: '10px' }} color='yellow' />
                </Link>

                <Header>
                    Please, use Desktop browser. Or make a window wider
                </Header>

            </div>
        </Media>
    )

}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
    <MediaContextProvider>
        <DesktopContainer>{children}</DesktopContainer>
        <MobileContainer>{children}</MobileContainer>
    </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}

const AddWorkerLayout = () => {
    return (
        <ResponsiveContainer />
    )
}

export default AddWorkerLayout