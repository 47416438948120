export const requestTypes = [
    {
        key: 'Sick',
        text: 'Sick',
        value: 'Sick',
        icon: 'medkit',
    },
    {
        key: 'Day-off',
        text: 'Day-off',
        value: 'Day-off',
        icon: 'tree'
    },
    {
        key: 'Reise',
        text: 'Reise',
        value: 'Reise',
        icon: 'industry'
    },
    {
        key: 'Berufsschule',
        text: 'Berufsschule',
        value: 'Berufsschule',
        icon: 'graduation cap'
    },
    {
        key: 'Sonderurlaub',
        text: 'Sonderurlaub',
        value: 'Sonderurlaub',
        icon: 'newspaper'
    },    
]