import React from 'react'

import WelcomePage from './pages/WelcomePage'
import WorkPage from './pages/WorkPage'
import LoginPage from './pages/LoginPage'
import RequestPage from './pages/RequestPage'
import RequestsPage from './pages/RequestsPage'
import WorkersListPage from './pages/WorkersListPage'
import AddWorker from './pages/AddWorker'
import WorkerWorktime from './pages/WorkerWorktime'
import CompanySettings from './pages/CompanySettings'

import backgroundImage from './images/background.jpg'

import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const styles = {
    header: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        backgroundSize: 'cover'
    },

    content: {
        backgroundColor: 'rgba(50, 50, 50, 0.5)',
        // color: 'white'
    }
}

const App = () => {
    return (
        <div className='main-app' style={styles.header}>
            <div style={styles.content}>
            <Router>
                <Switch>
                    <Route exact path="/" component={LoginPage} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/work" component={WorkPage} />
                    <Route path="/new-request" component={RequestPage} />
                    <Route path="/requests" component={RequestsPage} />
                    <Route path='/workers' component={WorkersListPage} />
                    <Route path='/add-worker/:workerPersonalId' component={AddWorker} />
                    <Route path='/add-worker' component={AddWorker} />
                    <Route path='/worktime/:workerPersonalId' component={WorkerWorktime} />
                    <Route path='/company-settings' component={CompanySettings} />
                </Switch>
            </Router>
            </div>
        </div>
    )
}

export default App