import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import { Button, Grid, Input } from 'semantic-ui-react'
import styles from '../styles';

import * as api from '../api'
import * as utils from '../utils'

const CompanySettings = () => {
    const [newCompanyLocation, setNewCompanyLocation] = useState('')
    const [holidaysCount, setHolidaysCount] = useState(-1)
    const [startHolidays, setStartHolidays] = useState(-1) // To prevent unnecessary calls to api (with setting the save value)
    const [holidaysCountButtonDisabled, setHolidaysCountButtonDisabled] = useState(true)
    const [newCompanyLocationButtonDisabled, setNewCompanyLocationDisabled] = useState(true)

    useEffect(() => {
        const token = localStorage.getItem('token')
        api.getCompanySettings(token, (res) => {
            if (res.data) {
                setHolidaysCount(res.data.general.defaultHolidaysCount)
                setStartHolidays(res.data.general.defaultHolidaysCount)
            }
        })
    }, [])

    useEffect(() => {
        setNewCompanyLocationDisabled(newCompanyLocation === '' || newCompanyLocation === undefined)
    }, [newCompanyLocation])
    useEffect(() => {
        setHolidaysCountButtonDisabled(!utils.isNumber(holidaysCount) || parseInt(holidaysCount) === startHolidays)
    }, [holidaysCount, startHolidays])
    
    const processNewLocation = () => {
        const token = localStorage.getItem('token')
        api.addNewCompanyLocation(token, newCompanyLocation, (res) => {
            if (res) {
                console.log('Res form adding company location')
            }
            setNewCompanyLocation('')
        })
    }

    const processNewHolidaysCount = () => {
        const token = localStorage.getItem('token')
        api.changeCompanySettings(token, 'defaultHolidaysCount', parseInt(holidaysCount), (res) => {
            console.log('Res data:', res.data)
            setHolidaysCount('')
        })
    }

    return (
        <div id='grid-table'>
            <Link to="/workers">
                <Button basic content='Back' icon='angle left' size='large' color='yellow'/>
            </Link>
            <Grid id='grid-no-margin' textAlign='center' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Input value={newCompanyLocation} placeholder='New Company location' onChange={e => setNewCompanyLocation(e.target.value)} action>
                            <input />
                            <Button disabled={newCompanyLocationButtonDisabled} content='Save new company location' onClick={processNewLocation} />
                        </Input>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Input value={holidaysCount} placeholder='Default holidays count' onChange={e => setHolidaysCount(e.target.value)} action>
                            <input />
                            <Button disabled={holidaysCountButtonDisabled} content='Save holidays count' onClick={processNewHolidaysCount} />
                        </Input>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default CompanySettings