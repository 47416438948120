import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";

import { Button, Header, Label, Form, Select, Divider, Table, Pagination, Icon, Grid } from 'semantic-ui-react'

import * as api from '../api/index';
import { minutesToHMString } from '../utils';


const WorkersListPage = () => {
    const startCompLoc = 'Select company location'
    const [selectedLocation, setSelectedLocation] = useState(startCompLoc)
    const [companyLocations, setCompanyLocations] = useState([{ key: 'a', value: 'a', text: 'a' }])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const [workers, setWorkers] = useState(
        // [
        // {
        //     _id: '123',
        //     personalId: 'Loading',
        //     firstName: 'Loading',
        //     lastName: 'Loading',
        //     timeModel: ,
        //     saldo: '-',
        //     status: 'Loading',
        //     companyLocations: 'Loading'
        // }
        // ]
    )

    useEffect(() => {
        const token = localStorage.getItem('token');
        api.getCompanySettings(token, (res) => {
            if (!res) return
            const compLocsFromDB = res.data.admin.companyLocations
            var compLocationsObjectsArr = []
            for (let i = 0; i < compLocsFromDB.length; i++) {
                const companyLocation = compLocsFromDB[i];
                compLocationsObjectsArr.push({ key: companyLocation, text: companyLocation, value: companyLocation })
            }
            setCompanyLocations(compLocationsObjectsArr)
            setSelectedLocation(compLocationsObjectsArr[0].value)
        });
    }, [])

    useEffect(() => {
        if (selectedLocation !== startCompLoc) {
            const token = localStorage.getItem('token');
            //TODO: add a dropdown to select a limit number (5,10,15,20) https://react.semantic-ui.com/modules/dropdown/#usage-controlled
            const limit = 5
            api.getWorkers(token, selectedLocation, page, limit, (response) => {
                if (!response) return
                const { results, next } = response
                const totalPages = next ? Math.max(1, next.max) : page
                setTotalPages(totalPages)
                setWorkers(results)
            })
        }
    }, [selectedLocation, page])

    const handlePageChange = (e, { activePage }) => setPage(activePage)

    const saldoColor = (worker) => {
        return worker.saldo === 0 || (worker.saldo && worker.saldo > 0)
            ? 'green'
            : 'red'
    }

    const renderWorkersTable = () => {
        return (
            <Table celled color='yellow' unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Person</Table.HeaderCell>
                        <Table.HeaderCell>Time Model</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Saldo</Table.HeaderCell>
                        <Table.HeaderCell>Settings</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>


                <Table.Body>
                    {workers.map((worker) => (
                        <Table.Row key={worker._id}>
                            <Table.Cell>
                                <Label>{worker.personalId.toString()}</Label>
                            </Table.Cell>
                            <Table.Cell>{worker.firstName.toString()}, {worker.lastName.toString()}</Table.Cell>
                            <Table.Cell>{worker.timeModel.general.toString()}</Table.Cell>
                            <Table.Cell>{worker.managerStatus.toString()}</Table.Cell>
                            <Table.Cell>
                                <Label color={saldoColor(worker)}>
                                    {minutesToHMString(worker.saldo.toString())}
                                </Label>
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`worktime/${worker.personalId}`}>
                                    <Button basic color='yellow' content='Worktime' />
                                </Link>
                                <Link to={`add-worker/${worker.personalId}`}>
                                    <Button basic color='yellow' content='Modify' />
                                </Link>
                            </Table.Cell>
                        </Table.Row>
                    ))
                    }
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6'>
                            <Pagination
                                floated='right'
                                totalPages={totalPages}
                                type='range'
                                activePage={page}
                                onPageChange={handlePageChange}
                            />

                            <Link to='/add-worker'>
                                <Button
                                    floated='left'
                                    icon
                                    labelPosition='left'
                                    color='yellow'
                                    size='large'
                                >
                                    <Icon name='user' />
                                    Add Worker
                                </Button>
                            </Link>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        )
    }

    return (
        <div id='grid-table'>
            <Link to="/work">
                <Button basic content='Back' icon='angle left' size='large' color='yellow' />
            </Link>
            <Link to="/company-settings">
                <Button
                    content='Company Settings'
                    icon='settings'
                    size='large'
                    floated='right'
                    color='yellow'
                    style={{ position: 'absolute', top: '1em', right: '1em' }}
                />
            </Link>
            <Grid textAlign='center' verticalAlign='middle'>
                <Form>
                    <Header as='h2' icon color='yellow'>
                        <Icon name='industry' />
                        <Header.Content>Company Locations</Header.Content>
                    </Header>

                    <Form.Field
                        control={Select}
                        options={companyLocations}
                        value={selectedLocation || companyLocations[0].value}
                        onChange={(e, rs) => setSelectedLocation(rs.value)}
                        placeholder='Company location'
                    />
                </Form>
            </Grid>
            <br />
            <Divider />
            {workers
                ? renderWorkersTable()
                : <h1>Loading...</h1>}
        </div>
    )

}

export default WorkersListPage;